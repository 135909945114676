/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import App from "./App";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*eslint-disable */
const firebaseConfig = {
    apiKey: "AIzaSyDNwgvz4v-AiJT85Kh_u8LDPwtYl9Ze1HU",
    authDomain: "siong-kee-eform.firebaseapp.com",
    databaseURL: "https://siong-kee-eform-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "siong-kee-eform",
    storageBucket: "siong-kee-eform.appspot.com",
    messagingSenderId: "566140336650",
    appId: "1:566140336650:web:8ccc37745e1cd812df3df3",
    measurementId: "G-10RW48K351",
};
/*eslint-enable */


const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <ThemeProvider>
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </PersistProvider>
    </Provider>
);
