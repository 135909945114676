import ReactTooltip from "react-tooltip";
import { MessageSquare, Bell, LogOut } from "react-feather";
import { StyledAlerts, StyledAlert } from "./style";
import { Button, NavLink } from "@doar/components";



const Alerts = (props: any) => {
    return (
        <StyledAlerts className="aside-alerts">

            <ReactTooltip place="top" effect="solid" id="tooltip-sign-out" />
            <Button color="dark" iconButton variant="texted" onClick={props.onClickLogout}>
                <LogOut size={16} strokeWidth="2.3px" />
            </Button>
            <StyledAlert
                path="#"
                data-for="tooltip-sign-out"
                data-tip="Sign out"
            >

            </StyledAlert>
        </StyledAlerts>
    );
};

export default Alerts;
