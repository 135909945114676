import { Suspense, lazy, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    useNavigate,
    useLocation
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";
import Layout02 from "./layouts/layout-02";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


// Classic Pages
// const SignIn = lazy(() => import("./pages/signin"));
// const SignUp = lazy(() => import("./pages/signup"));
// const VerifyAccount = lazy(() => import("./pages/verify-account"));
// const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));

// SIONGKEE ROUTES
const Welcome = lazy(() => import("./pages/welcome-page"));

const PilingRecordSummary = lazy(() => import("./pages/piling/piling-record-summary"));
const PilingRecords = lazy(() => import("./pages/piling/piling-records"));
const PilingRecordSummaryDrivenPile = lazy(() => import("./pages/piling/piling-record-summary-driven-pile"));
const PilingRecordSummaryJackInPile = lazy(() => import("./pages/piling/piling-record-summary-jack-in-pile"));
const DailyProgressReport = lazy(() => import("./pages/piling/daily-progress-report"));

const PreBoringRecords = lazy(() => import("./pages/preboring-reliefwell/preboring-records"));
const ReliefWellRecords = lazy(() => import("./pages/preboring-reliefwell/reliefwell-records"));

const WorkerSalaryBiMonthly = lazy(() => import("./pages/workers/workers-salary-bi-monthly"));
const WorkerSalaryBiMonthly1631 = lazy(() => import("./pages/workers/workers-salary-bi-monthly-16-31"));
const WorkerSalaryBiMonthly131 = lazy(() => import("./pages/workers/workers-salary-1-31"));
const WorkerSalaryMonthly = lazy(() => import("./pages/workers/workers-salary-monthly"));

const BoredRecords = lazy(() => import("./pages/bored/bored-records"));
const BoredMicropileRecords = lazy(() => import("./pages/bored/bored-micropile-records"));


// END SIONGKEE ROUTES

// Classic Plus Pages

const Dashboard = lazy(() => import("./pages/dashboard-one"));
// const ClassicPlusDashboardTwo = lazy(
//     () => import("./pages/dashboard-two")
// );
// const ClassicPlusDashboardThree = lazy(
//     () => import("./pages/dashboard-three")
// );
// const ClassicPlusDashboardFour = lazy(
//     () => import("./pages/dashboard-four")
// );
// const ClassicPlusDashboardFive = lazy(
//     () => import("./pages/dashboard-five")
// );
const ClassicPlusSignIn = lazy(() => import("./pages/signin"));
const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));
const ClassicPlusVerifyAccount = lazy(
    () => import("./pages/classic-plus/verify-account")
);
const ClassicPlusForgotPassword = lazy(
    () => import("./pages/classic-plus/forgot-password")
);
const ClassicPlusErrorNotFound = lazy(
    () => import("./pages/classic-plus/error-404")
);
const ClassicPlusError500 = lazy(
    () => import("./pages/classic-plus/error-500")
);
const ClassicPlusError503 = lazy(
    () => import("./pages/classic-plus/error-503")
);
const ClassicPlusError505 = lazy(
    () => import("./pages/classic-plus/error-505")
);

const ClassicPlusProfileView = lazy(
    () => import("./pages/classic-plus/profile-view")
);
const ClassicPlusConnections = lazy(
    () => import("./pages/classic-plus/connections")
);
const ClassicPlusGroups = lazy(() => import("./pages/classic-plus/groups"));
const ClassicPlusEvents = lazy(() => import("./pages/classic-plus/events"));
const ClassicPlusTimeline = lazy(() => import("./pages/classic-plus/timeline"));
const ClassicPlusPricing = lazy(() => import("./pages/classic-plus/pricing"));
const ClassicPlusHelpCenter = lazy(
    () => import("./pages/classic-plus/help-center")
);
const ClassicPlusInvoice = lazy(() => import("./pages/classic-plus/invoice"));
const ClassicPlusCalendar = lazy(
    () => import("./pages/classic-plus/apps/calendar")
);
const ClassicPlusChat = lazy(() => import("./pages/classic-plus/apps/chat"));
const ClassicPlusContacts = lazy(
    () => import("./pages/classic-plus/apps/contacts")
);
const ClassicPlusFileManager = lazy(
    () => import("./pages/classic-plus/apps/file-manager")
);
const ClassicPlusMail = lazy(() => import("./pages/classic-plus/apps/mail"));

const App = () => {    
    const navigate = useNavigate();
    const location = useLocation();

    const firebaseConfig = {
        apiKey: "AIzaSyDNwgvz4v-AiJT85Kh_u8LDPwtYl9Ze1HU",
        authDomain: "siong-kee-eform.firebaseapp.com",
        databaseURL: "https://siong-kee-eform-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "siong-kee-eform",
        storageBucket: "siong-kee-eform.appspot.com",
        messagingSenderId: "566140336650",
        appId: "1:566140336650:web:8ccc37745e1cd812df3df3",
        measurementId: "G-10RW48K351",
    };
    
    

    useEffect(() => {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);        
        auth.onIdTokenChanged((user) => {
          if (user) {
            user.getIdToken().then((token) =>{
                console.log("reach tokek", token)
                localStorage.setItem('token', token);
            }).catch((err) => {
                console.error("on token change no token", err)
            })
          } else {
            localStorage.removeItem('token');
          }
        });
    
        axios.interceptors.request.use((config) => {
          const token = localStorage.getItem('token');
    
          if (token) {
            config.headers.token = token;
          }
    
          return config;
        });
      }, [location]);
    
    
    

    // useEffect(() => {
    //     auth.currentUser?.getIdToken(true).then((token)=> {
    //         if (token && token !== ""){
    //             axios.interceptors.request.use(
    //                 config => {
    //                     console.log("token", token)
    //                   config.headers.token = token;
    //                       return config;
    //                   },
    //                   error => {
    //                       return Promise.reject(error);
    //                   }
    //             );
    //             // axiosInstance.defaults.headers.common.token = token;
    //         } else {
    //             axios.interceptors.request.use(
    //                 config => {
    //                   config.headers.token = '';
    //                       return config;
    //                   },
    //                   error => {
    //                       return Promise.reject(error);
    //                   }
    //             );
    
    //         }
    //     }).catch((err) =>{console.log(err)})      
    
    //   }, [location]);

    return (
        <>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        {/* Classic Routes */}

                        {/* Apps Routes */}

                        {/* App Route With Sidebar Layout 2 */}
                        {/* <Route
                            element={
                                <Layout hasSidebar hideFooter sidebarLayout={2}>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            <Route
                                path="/apps/contacts"
                                element={<Contacts />}
                            />
                            <Route path="/apps/chat" element={<Chat />} />
                        </Route> */}

                        {/* App Route With Sidebar*/}
                        {/* <Route
                            element={
                                <Layout hasSidebar hideFooter>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            <Route
                                path="/apps/calendar"
                                element={<Calendar />}
                            />
                            <Route
                                path="/apps/file-manager"
                                element={<FileManager />}
                            />
                            <Route path="/apps/mail" element={<Mail />} />
                        </Route> */}

                        {/* No side bar, no footer no search */}
                        <Route
                            element={
                                <Layout hideHeader hideFooter>
                                    <Outlet />
                                </Layout>
                            }
                        >
                            <Route
                                path="/signin"
                                element={<ClassicPlusSignIn />}
                            />
                        </Route>

                        {/* Classic Plus Routes */}

                        <Route
                            element={
                                <Layout02>
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            {/* Dashboard Routes */}
                            <Route path="/" element={<Welcome />} />

                            {/* Piling Routes */}
                            <Route path="/piling/piling-records" element={<PilingRecords />} />
                            <Route path="/piling/record-summary" element={<PilingRecordSummary />} />
                            <Route path="/piling/daily-progress" element={<DailyProgressReport />} />
                            
                            {/* Pre-Boring & Relief Well */}
                            <Route path="/pre-boring-relief-well/pre-boring" element={<PreBoringRecords />} />
                            <Route path="/pre-boring-relief-well/relief-well" element={<ReliefWellRecords />} />

                            {/* <Route path="/piling/record-summary/driven-pile" element={<PilingRecordSummaryDrivenPile />} />
                            <Route path="/piling/record-summary/jack-in-pile" element={<PilingRecordSummaryJackInPile />} /> */}

                            <Route path="/workers/bi-monthly-1-15" element={<WorkerSalaryBiMonthly />} />
                            <Route path="/workers/bi-monthly-16-31" element={<WorkerSalaryBiMonthly1631 />} />
                            <Route path="/workers/fw-monthly" element={<WorkerSalaryBiMonthly131 />} />
                            <Route path="/workers/subworkers-monthly" element={<WorkerSalaryMonthly />} />
                            
                            {/* Bored Routes */}
                            <Route path="/bored/bored-records" element={<BoredRecords />} />
                            <Route path="/bored/bored-micropile-records" element={<BoredMicropileRecords />} />


                            {/* <Route
                                path="/classic-plus/dashboard-one"
                                element={<ClassicPlusDashboardOne />}
                            />
                            <Route
                                path="/classic-plus/dashboard-two"
                                element={<ClassicPlusDashboardTwo />}
                            />
                            <Route
                                path="/classic-plus/dashboard-three"
                                element={<ClassicPlusDashboardThree />}
                            />
                            <Route
                                path="/classic-plus/dashboard-four"
                                element={<ClassicPlusDashboardFour />}
                            />
                            <Route
                                path="/classic-plus/dashboard-five"
                                element={<ClassicPlusDashboardFive />}
                            /> */}

                            {/* Authentication Routes */}
                            {/* <Route
                                path="/signin"
                                element={<ClassicPlusSignIn />}
                            /> */}

                            {/* Error Routes */}
                            <Route
                                path="/classic-plus/error-500"
                                element={<ClassicPlusError500 />}
                            />
                            <Route
                                path="/classic-plus/error-503"
                                element={<ClassicPlusError503 />}
                            />
                            <Route
                                path="/classic-plus/error-505"
                                element={<ClassicPlusError505 />}
                            />
                            <Route
                                path="/classic-plus/error-404"
                                element={<ClassicPlusErrorNotFound />}
                            />

                            {/* User Routes */}
                            <Route
                                path="/classic-plus/profile-view"
                                element={<ClassicPlusProfileView />}
                            />
                            <Route
                                path="/classic-plus/connections"
                                element={<ClassicPlusConnections />}
                            />
                            <Route
                                path="/classic-plus/groups"
                                element={<ClassicPlusGroups />}
                            />
                            <Route
                                path="/classic-plus/events"
                                element={<ClassicPlusEvents />}
                            />

                            {/* Other Routes */}
                            <Route
                                path="/classic-plus/timeline"
                                element={<ClassicPlusTimeline />}
                            />
                            <Route
                                path="/classic-plus/pricing"
                                element={<ClassicPlusPricing />}
                            />
                            <Route
                                path="/classic-plus/help-center"
                                element={<ClassicPlusHelpCenter />}
                            />
                            <Route
                                path="/classic-plus/invoice"
                                element={<ClassicPlusInvoice />}
                            />
                        </Route>

                        {/* Apps Routes */}

                        {/* App Route With Minimize Sidebar*/}
                        <Route
                            element={
                                <Layout02 aside="minimize">
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route
                                path="/classic-plus/apps/calendar"
                                element={<ClassicPlusCalendar />}
                            />
                            <Route
                                path="/classic-plus/apps/file-manager"
                                element={<ClassicPlusFileManager />}
                            />
                            <Route
                                path="/classic-plus/apps/mail"
                                element={<ClassicPlusMail />}
                            />
                        </Route>

                        {/* App Route With Sidebar Layout 2*/}
                        <Route
                            element={
                                <Layout02 aside="minimize">
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route
                                path="/classic-plus/apps/chat"
                                element={<ClassicPlusChat />}
                            />
                            <Route
                                path="/classic-plus/apps/contacts"
                                element={<ClassicPlusContacts />}
                            />
                        </Route>

                        {/* 404 Page Route */}
                        <Route
                            element={
                                <Layout02>
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route path="*" element={<ErrorNotFound />} />
                        </Route>
                    </Routes>
                </Suspense>
        </>
    );
};

export default App;
