import {
    Calendar,
    MessageSquare,
    Users,
    FileText,
    Mail,
    LogIn,
    UserPlus,
    UserCheck,
    ShieldOff,
    User,
    File,
    PieChart,
    Package,
    Tool,
    Layers,
    Box,
    Archive,
    AlertCircle,
    ShoppingBag
} from "react-feather";

const asideMenus = [
    {
        id: 2,
        label: "Worker's Salary",
        url: "/",
        Icon: Tool,
        megamenu: [
            {
                id: 21,
                title: "Workers",
                Icon: Layers,
                submenu: [
                    {
                        id: 211,
                        label: "Bi-monthly 1-15",
                        url: "/workers/bi-monthly-1-15",
                        Icon: LogIn,
                    },
                    {
                        id: 212,
                        label: "Bi-monthly 16-31",
                        url: "/workers/bi-monthly-16-31",
                        Icon: LogIn,
                    },
                    {
                        id: 213,
                        label: "FW Monthly",
                        url: "/workers/fw-monthly",
                        Icon: LogIn,
                    },
                    {
                        id: 214,
                        label: "Subworkers Monthly",
                        url: "/workers/subworkers-monthly",
                        Icon: UserPlus,
                    }
                ],
            },
        ],
    },
    {      
        id: 3,
        label: "Piling Record Summary",
        url: "/",
        Icon: Layers,
        megamenu: [
            {
                id: 31,
                title: "Piling Record Summary",
                Icon: Layers,
                submenu: [
                    {
                        id: 311,
                        label: "Piling Record Summary",
                        url: "/piling/record-summary",
                        Icon: UserPlus,
                    },
                    {
                        id: 312,
                        label: "Piling Records",
                        url: "/piling/piling-records",
                        Icon: UserPlus,
                    },
                    {
                        id: 312,
                        label: "Daily Progress Report",
                        url: "/piling/daily-progress",
                        Icon: UserPlus,
                    },
                ],
            },
        ]
        
    },
    {      
        id: 4,
        label: "Pre-Boring & Well Relief Record Summary",
        url: "/",
        Icon: Layers,
        megamenu: [
            {
                id: 41,
                title: "Records",
                Icon: Layers,
                submenu: [
                    {
                        id: 411,
                        label: "Records",
                        url: "/pre-boring-relief-well/pre-boring",
                        Icon: UserPlus,
                    },
                    // {
                    //     id: 411,
                    //     label: "Relief Well Records",
                    //     url: "/pre-boring-relief-well/relief-well",
                    //     Icon: UserPlus,
                    // },
                    // {
                    //     id: 412,
                    //     label: "Pre-Boring Records",
                    //     url: "/pre-boring-relief-well/pre-boring",
                    //     Icon: UserPlus,
                    // },
                ],
            },
        ]
        
    },
    {      
        id: 5,
        label: "Bored Summary",
        url: "/",
        Icon: Layers,
        megamenu: [
            {
                id: 51,
                title: "Bored Summary",
                Icon: Layers,
                submenu: [
                    {
                        id: 311,
                        label: "Bored Records",
                        url: "/bored/bored-records",
                        Icon: UserPlus,
                    },
                    {
                        id: 311,
                        label: "Bored Micropile Records",
                        url: "/bored/bored-micropile-records",
                        Icon: UserPlus,
                    },
                ],
            },
        ]
        
    },
    // {
    //     id: 3,
    //     label: "Pages",
    //     url: "/",
    //     Icon: Layers,
    //     megamenu: [
    //         {
    //             id: 31,
    //             title: "Authentication",
    //             Icon: Layers,
    //             submenu: [
    //                 {
    //                     id: 311,
    //                     label: "Sign In",
    //                     url: "/classic-plus/signin",
    //                     Icon: LogIn,
    //                 },
    //                 {
    //                     id: 312,
    //                     label: "Sign Up",
    //                     url: "/classic-plus/signup",
    //                     Icon: UserPlus,
    //                 },
    //                 {
    //                     id: 313,
    //                     label: "Verify Account",
    //                     url: "/classic-plus/verify-account",
    //                     Icon: UserCheck,
    //                 },
    //                 {
    //                     id: 314,
    //                     label: "Forgot Password",
    //                     url: "/classic-plus/forgot-password",
    //                     Icon: ShieldOff,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 32,
    //             title: "Error Pages",
    //             Icon: AlertCircle,
    //             submenu: [
    //                 {
    //                     id: 321,
    //                     label: "404 Page Not Found",
    //                     url: "/classic-plus/error-404",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 322,
    //                     label: "500 Internal Server",
    //                     url: "/classic-plus/error-500",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 323,
    //                     label: "503 Service Unavailable",
    //                     url: "/classic-plus/error-503",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 324,
    //                     label: "505 Forbidden",
    //                     url: "/classic-plus/error-505",
    //                     Icon: File,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 33,
    //             title: "User Pages",
    //             Icon: User,
    //             submenu: [
    //                 {
    //                     id: 331,
    //                     label: "View Profile",
    //                     url: "/classic-plus/profile-view",
    //                     Icon: User,
    //                 },
    //                 {
    //                     id: 332,
    //                     label: "Connections",
    //                     url: "/classic-plus/connections",
    //                     Icon: Users,
    //                 },
    //                 {
    //                     id: 333,
    //                     label: "Groups",
    //                     url: "/classic-plus/groups",
    //                     Icon: Users,
    //                 },
    //                 {
    //                     id: 334,
    //                     label: "Events",
    //                     url: "/classic-plus/events",
    //                     Icon: Calendar,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 34,
    //             title: "Other Pages",
    //             Icon: File,
    //             submenu: [
    //                 {
    //                     id: 341,
    //                     label: "Timeline",
    //                     url: "/classic-plus/timeline",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 342,
    //                     label: "Pricing",
    //                     url: "/classic-plus/pricing",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 343,
    //                     label: "Help Center",
    //                     url: "/classic-plus/help-center",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 344,
    //                     label: "Invoice",
    //                     url: "/classic-plus/invoice",
    //                     Icon: FileText,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 4,
    //     label: "User Interface",
    //     url: "/",
    //     Icon: Package,
    //     submenu: [
    //         {
    //             id: 41,
    //             label: "Components",
    //             url: "https://doar-component.jamstacktemplates.dev/?path=/docs/introduction--page",
    //             Icon: Box,
    //         },
    //         {
    //             id: 42,
    //             label: "Collections",
    //             url: "#!",
    //             Icon: Archive,
    //         },
    //     ],
    // },
];

export default asideMenus;
