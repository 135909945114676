import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, AvatarInitial } from "@doar/components";
import Alerts from "./alerts";
import axios from "axios";
import LoggedinUser from "./loggedin-user";
import { StyledUser, StyledAvatarWrap } from "./style";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AsideUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fullname = localStorage.getItem("fullname");
    const role = localStorage.getItem("role");
    const initials = fullname?.split(" ").map(word => word[0]).join('')
    const [isAuthenticated, setIsAuthenticated] = useState<any>(null);



    const firebaseConfig = {
        apiKey: "AIzaSyDNwgvz4v-AiJT85Kh_u8LDPwtYl9Ze1HU",
        authDomain: "siong-kee-eform.firebaseapp.com",
        databaseURL: "https://siong-kee-eform-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "siong-kee-eform",
        storageBucket: "siong-kee-eform.appspot.com",
        messagingSenderId: "566140336650",
        appId: "1:566140336650:web:8ccc37745e1cd812df3df3",
        measurementId: "G-10RW48K351",
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);



    useEffect(() => {
        if (localStorage.getItem("fullname")) {
            // navigate("/setup", { state: { from: retLoc || "/dashboard" } });

        }
    }, []);

    useEffect(() => {
        const auth = getAuth();

        const listener = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => {
            listener();
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated !== false) {
            console.log("Logged in")
        } else {
            console.log("Logged out")
            navigate("/signin")
        }
    }, [isAuthenticated])

    const onLogout = () => {
        const auth = getAuth(app);
        signOut(auth).then(() => {
            localStorage.removeItem('fullname');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            axios.interceptors.request.use(
                config => {
                  config.headers.token = '';
                      return config;
                  },
                  error => {
                      return Promise.reject(error);
                  }
            );

            // navigate("/signin")
        }).catch((error) => {
            console.error("Unable to logout.")
        });
    }

    return (
        <StyledUser className="aside-user">
            <StyledAvatarWrap>
                <Avatar size="md">
                    <AvatarInitial>{initials}</AvatarInitial>
                </Avatar>
                <Alerts onClickLogout={onLogout} />
            </StyledAvatarWrap>
            <LoggedinUser fullname={fullname} role={role} />
        </StyledUser>
    );
};

export default AsideUser;
